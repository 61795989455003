import { createSlice } from "@reduxjs/toolkit";

const isForbidden = (action) => {
	return action.type.endsWith("rejected") && action.payload === 403;
};

const namespace = "message";

const initialState = {
	success: null,
	error: null,
	tempSuccess: null,
	tempError: null
};

const messageSlice = createSlice({
	name: namespace,
	initialState,
	reducers: {
		setError: (state, action) => {
			state.error = action.payload;
		},
		setSuccess: (state, action) => {
			state.success = action.payload;
		},
		setTempError: (state, action) => {
			state.tempError = action.payload;
		},
		setTempSuccess: (state, action) => {
			state.tempSuccess = action.payload;
		},
		clearAllMessages: (state, action) => {
			state.success = null;
			state.error = null;
			state.tempSuccess = null;
			state.tempError = null;
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(isForbidden, (state, { payload }) => {
			state.tempSuccess = "Dazu hast Du keine Berechtigung";
		});
	}
});

export const { setError, setSuccess, setTempError, setTempSuccess, clearAllMessages } = messageSlice.actions;

export default messageSlice.reducer;
