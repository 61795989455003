import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import TempSuccess from "../../ui/TempSuccess";
import TempError from "../../ui/TempError";
import Menu from "../../components/Menu/Menu";
import Spinner from "../../ui/Spinner";
import AerztefonLogo from "../../ui/AerztefonLogo";

const getSystemSign = () => {
    if (process.env.REACT_APP_ENV === "test") {
        return "TEST";
    }
    if (process.env.REACT_APP_ENV === "development") {
        return "DEV";
    }
};

const Layout = () => {
    const loading = useSelector((state) => state.loading);

    return (
        <Fragment>
            <TempSuccess />
            <TempError />
            <div id="topBar">
                {process.env.REACT_APP_ENV !== "production" && <div className="development">{getSystemSign()}</div>}
                <h1>Start</h1>
                <Menu />
                <AerztefonLogo />
            </div>
            <main>
                <Outlet />
            </main>
            {loading && <Spinner />}
        </Fragment>
    );
};

export default Layout;
