import { useEffect } from "react";
import useMobileApi from "../../../../hooks/useMobileApi";

const WaitForConfirmation = ({ updateRegistration, callbackId }) => {
    const result = useMobileApi(callbackId, "onboarding");

    useEffect(() => {
        if (result) {
            updateRegistration(result.callbackId, result.platform, result.installationId, result.pushToken);
        }
    }, [result, updateRegistration]);

    return null;
};

export default WaitForConfirmation;
