import axios from "axios";

export const refreshToken = async (time) => {
    //try {
    const result = await axios.post(process.env.REACT_APP_AXIOS_URL + "refreshToken", {}, { withCredentials: true });
    return result;
    //} catch (err) {
    //  console.log("refreshToken err", err);
    //throw new Error();
    //}
};
