import styles from "./TeaserButton.module.css";

const TeaserButton = ({ url, name, icon }) => {
	const goTo = () => {
		window.location.href = url;
	};

	return (
		<div className={styles.teaserButton} onClick={goTo}>
			{icon && <img src={icon} alt={name} />}
			<div>{name}</div>
		</div>
	);
};

export default TeaserButton;
