import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { checkLoginByLink, loginByLink } from "../../../features/auth/authSlice";

import Panel from "../../../hoc/Panel/Panel";

import styles from "./LinkLogin.module.css";
import Label from "../../../ui/Label/Label";
import TimeoutBar from "../../../ui/TimeoutBar/TimeoutBar";
import * as routes from "../../../shared/routes";
import Buttons from "../../../ui/Buttons/Buttons";

const LinkLogin = () => {
    const [password, setPassword] = useState("");
    const { callbackId } = useParams();
    const validityPeriod = useSelector((state) => state.auth.validityPeriod);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!validityPeriod) {
            dispatch(checkLoginByLink(callbackId));
        }
    }, [validityPeriod, callbackId, dispatch]);

    const changePasswort = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = (event) => {
        event.preventDefault();
        dispatch(loginByLink({ callbackId, password }));
    };
    return (
        <Panel title="Anmelden" size="small">
            <form className={styles.content} onSubmit={handleLogin}>
                {validityPeriod && (
                    <>
                        <Label label="Passwort" required error={password.length < process.env.REACT_APP_MIN_PASSWORD_LENGTH}>
                            <input type="password" autoComplete="current-password" value={password} onChange={changePasswort} />
                        </Label>
                        <TimeoutBar seconds={validityPeriod} />
                    </>
                )}
                <Buttons>
                    <button type="button" onClick={() => navigate(routes.HOME)}>
                        Abbrechen
                    </button>
                    <button type="submit" disabled={!validityPeriod || password.length < process.env.REACT_APP_MIN_PASSWORD_LENGTH}>
                        Anmelden
                    </button>
                </Buttons>
            </form>
        </Panel>
    );
};

export default LinkLogin;
