import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../hoc/Panel/Panel";

import styles from "./TwoFA.module.css";
import { getTwoFaData, updateTwoFa } from "../../features/twoFA/twoFaSlice";
import { useNavigate } from "react-router-dom";
import * as routes from "../../shared/routes";
import Buttons from "../../ui/Buttons/Buttons";
import RegisterPhone from "./RegisterPhone/RegisterPhone";

const TwoFA = () => {
    const [register, setRegister] = useState(false);
    const dispatch = useDispatch();
    const platform = useSelector((state) => state.twoFa.platform);
    const installationId = useSelector((state) => state.twoFa.installationId);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getTwoFaData());
    }, [dispatch]);

    const updateRegistration = (callbackId, platform, installationId, pushToken) => {
        dispatch(updateTwoFa({ phoneId: callbackId, platform, installationId, pushToken }));
        setRegister(false);
    };

    return (
        <Panel title="2-Faktor-Authentifizierung" size="medium">
            <div className={styles.content}>
                {register ? (
                    <RegisterPhone updateRegistration={updateRegistration} cancel={() => setRegister(false)} />
                ) : (
                    <>
                        <div className={styles.registeredPhone}>
                            {installationId ? (
                                <>
                                    <div>
                                        <span className={styles.okSign}>✔</span> Dein {platform}-Mobiltelefon ist registriert ({installationId.substr(0, 6)})
                                    </div>
                                    <button type="button" onClick={() => setRegister(true)}>
                                        Mobiltelefon neu registrieren
                                    </button>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <span className={styles.errorSign}>✘</span> Dein Mobiltelefon ist nicht registriert
                                    </div>
                                    <button type="button" onClick={() => setRegister(true)}>
                                        Mobiltelefon registrieren
                                    </button>
                                </>
                            )}
                        </div>
                        <Buttons border>
                            <button type="button" onClick={() => navigate(routes.HOME)}>
                                Zurück
                            </button>
                        </Buttons>
                    </>
                )}
            </div>
        </Panel>
    );
};

export default TwoFA;
