import { capsCount, numberCount, smallCount, symbolCount } from "../../shared/password";
import styles from "./Instructions.module.css";

const getSign = (ok) => {
    return ok ? <span className={styles.success}>✔</span> : <span className={styles.error}>✘</span>;
};

const Instructions = ({ password }) => {
    return (
        <div className={styles.instruction}>
            Das Passwort muss mindestens {process.env.REACT_APP_MIN_PASSWORD_LENGTH} Zeichen
            {getSign(password.length >= process.env.REACT_APP_MIN_PASSWORD_LENGTH)}
            haben, bestehend aus grossen
            {getSign(capsCount(password))}
            und kleinen
            {getSign(smallCount(password))}
            Buchstaben, mindestens einer Zahl
            {getSign(numberCount(password))}
            und einem Sonderzeichen (@$!%*?&...)
            {getSign(symbolCount(password))}
        </div>
    );
};

export default Instructions;
