import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { login } from "../../features/auth/authSlice";

import Panel from "../../hoc/Panel/Panel";
import Error from "../../ui/Error";

import * as routes from "../../shared/routes";
import styles from "./Login.module.css";
import Label from "../../ui/Label/Label";
import { validateEmail } from "../../shared/utility";
import Buttons from "../../ui/Buttons/Buttons";

const Login = () => {
    const [eMail, setEMail] = useState("");
    const [password, setPassword] = useState("");
    const validityCode = useSelector((state) => state.auth.validityCode);
    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        if (validityCode) {
            navigate(routes.CONFIRM_2FA_LOGIN);
        }
    }, [validityCode, navigate]);

    const changeEMail = (event) => {
        setEMail(event.target.value);
    };

    const changePasswort = (event) => {
        setPassword(event.target.value);
    };

    const handleLogin = (event) => {
        event.preventDefault();
        dispatch(login({ eMail, password }));
    };
    return (
        <Panel title="Anmelden" size="small">
            <Error />
            <form className={styles.login} onSubmit={handleLogin}>
                <Label label="E-Mail" required error={!validateEmail(eMail)}>
                    <input type="text" autoComplete="username" value={eMail} onChange={changeEMail} />
                </Label>
                <Label label="Passwort" required error={password.length < process.env.REACT_APP_MIN_PASSWORD_LENGTH}>
                    <input type="password" autoComplete="current-password" value={password} onChange={changePasswort} />
                </Label>
                <Buttons>
                    <div></div>
                    <button type="submit">Anmelden</button>
                </Buttons>
                <div>
                    Ich habe mein <Link to={routes.PASSWORD_FORGOTTEN}>Passwort vergessen</Link>
                </div>
            </form>
        </Panel>
    );
};

export default Login;
