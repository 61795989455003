import axios from "axios";
import pMemoize from "p-memoize";
import { refreshToken } from "./refreshToken";

export const memoizedRefreshToken = pMemoize(refreshToken);

const instance = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_URL,
    withCredentials: true
});

let time = 0;
let timeOut = true;

const memTimeout = () => {
    if (timeOut) {
        timeOut = false;
        window.setTimeout(() => (timeOut = true), 5000);
        time++;
    }
    //console.log("time", time);
    return time;
};

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            await memoizedRefreshToken(memTimeout());
            // const result = await memoizedRefreshToken(memTimeout());
            //console.log("result of get refresh token", result);
            return instance(originalRequest);
        }
        return Promise.reject(error);
    }
);

export default instance;
