import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./WaitForMobileConfirmation.module.css";
import { cancel2FaLogin, final2FaLogin, delete2FaLogin, clearValidityCode } from "../../../features/auth/authSlice";
import useMobileApi from "../../../hooks/useMobileApi";
import Panel from "../../../hoc/Panel/Panel";
import { useNavigate } from "react-router-dom";
import * as routes from "../../../shared/routes";
import TimeoutBar from "../../../ui/TimeoutBar/TimeoutBar";

const WaitForMobileConfirmation = () => {
    const dispatch = useDispatch();
    const timeoutRef = useRef();
    const validityCode = useSelector((state) => state.auth.validityCode);
    const validityPeriod = useSelector((state) => state.auth.validityPeriod);
    const message = useSelector((state) => state.auth.message);
    const callbackId = useSelector((state) => state.auth.callbackId);
    const result = useMobileApi(callbackId, "login");
    const navigate = useNavigate();

    useEffect(() => {
        if (result !== null) {
            if (result.loginApproved) {
                dispatch(final2FaLogin(callbackId));
            } else {
                dispatch(cancel2FaLogin());
            }
        }
    }, [result, dispatch, callbackId]);

    useEffect(() => {
        if (!callbackId) {
            navigate(routes.HOME);
        }
    }, [callbackId, navigate]);

    useEffect(() => {
        if (!validityCode) {
            navigate(routes.TIMEOUT_2FA_LOGIN);
        }
    }, [validityCode, navigate]);

    useEffect(() => {
        if (validityPeriod) {
            //console.log("Start Timer with validity period:", validityPeriod);
            timeoutRef.current = window.setTimeout(() => dispatch(clearValidityCode()), validityPeriod * 1000);
        }
    }, [dispatch, validityPeriod]);

    useEffect(() => {
        return () => {
            //console.log("clearTimeout");
            window.clearTimeout(timeoutRef.current);
        };
    }, []);

    return (
        <Panel title="Anmeldung bestätigen" size="small">
            <div className={styles.content}>
                {message}
                <div>Kontrolliere ob der Code «{validityCode}» angezeigt wird.</div>
                <TimeoutBar seconds={validityPeriod} />
                <div>
                    <button type="button" onClick={() => dispatch(delete2FaLogin(callbackId))}>
                        Abbrechen
                    </button>
                </div>
            </div>
        </Panel>
    );
};

export default WaitForMobileConfirmation;
