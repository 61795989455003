import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { passwordForgotten } from "../../features/auth/authSlice";
import Panel from "../../hoc/Panel/Panel";
import styles from "./PasswordForgotten.module.css";

import { validateEmail } from "../../shared/utility";
import * as routes from "../../shared/routes";
import Label from "../../ui/Label/Label";
import Buttons from "../../ui/Buttons/Buttons";

const PasswordForgotten = () => {
    const dispatch = useDispatch();
    const [eMail, setEMail] = useState("");
    const navigate = useNavigate();

    const save = () => {
        dispatch(passwordForgotten({ eMail }));
    };

    const change = (e) => {
        const value = e.target.value;
        setEMail(value);
    };

    const cancel = () => {
        navigate(routes.LOGIN);
    };

    return (
        <Panel title="Passwort vergessen" size="small">
            <div className={styles.content}>
                <div>Bitte gib Deine bei uns registrierte E-Mail Adresse an. Du bekommst anschliessend eine E-Mail mit Instruktionen zur Rücksetzung Deines Passwortes.</div>

                <Label label="Deine E-Mail Adresse" required error={!validateEmail(eMail)}>
                    <input type="text" name="eMail" value={eMail} onChange={change} />
                </Label>
                <Buttons>
                    <button type="button" onClick={cancel}>
                        Abbrechen
                    </button>
                    <button type="button" onClick={save} disabled={!validateEmail(eMail)}>
                        Absenden
                    </button>
                </Buttons>
            </div>
        </Panel>
    );
};

export default PasswordForgotten;
