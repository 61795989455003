import { createSlice } from "@reduxjs/toolkit";

const namespace = "loading";

const isLoadingAction = (action) => {
	return action.type.endsWith("pending");
};

const isFinishedLoadingAction = (action) => {
	return action.type.endsWith("fulfilled") || action.type.endsWith("rejected");
};

const loadingSlice = createSlice({
	name: namespace,
	initialState: false,
	reducers: {
		startLoading: (state) => (state = true)
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(isLoadingAction, (state, action) => {
				return true;
			})
			.addMatcher(isFinishedLoadingAction, (state, action) => {
				return false;
			});
	}
});

export const { startLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
