import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../features/auth/authSlice";
import Panel from "../../hoc/Panel/Panel";
import styles from "./ChangePassword.module.css";

import * as routes from "../../shared/routes";
import { useNavigate } from "react-router-dom";
import Instructions from "../../components/Password/Instructions";
import { getPasswordError, passwordValid } from "../../shared/password";
import Label from "../../ui/Label/Label";
import Buttons from "../../ui/Buttons/Buttons";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.application.user?.id);
    const [data, setData] = useState({
        currentPassword: "",
        password: "",
        confirmPassword: ""
    });
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const ref = useRef();

    useEffect(() => {
        if (userId) {
            ref.current.focus();
        }
    }, [userId]);

    if (!userId) {
        return null;
    }

    const cancel = () => {
        navigate(routes.HOME);
    };

    const save = () => {
        const passwordError = getPasswordError(data.password, data.confirmPassword);
        if (passwordError) {
            setError(passwordError);
            return;
        }
        if (data.password === data.currentPassword) {
            setError("Das alte und das neue Passwort dürfen nicht identisch sein");
            return;
        }
        dispatch(updatePassword({ id: userId, data: { password: data.password, confirmPassword: data.confirmPassword, currentPassword: data.currentPassword } }));
    };

    const change = (e) => {
        const fieldname = e.target.name;
        const value = e.target.value;
        setData((prev) => ({ ...prev, [fieldname]: value }));
        setError("");
    };

    return (
        <Panel title="Passwort ändern" size="small">
            {error && <div className="error">{error}</div>}
            <form className={styles.content}>
                <Label label="Aktuelles Passwort" required error={!passwordValid(data.currentPassword)}>
                    <input ref={ref} type="password" autoComplete="no" name="currentPassword" value={data.currentPassword} onChange={change} />
                </Label>
                <Label label="Neues Passwort" required error={!passwordValid(data.password)}>
                    <input type="password" autoComplete="new-password" name="password" value={data.password} onChange={change} />
                </Label>
                <Label label="Neues Passwort (Bestätigung)" required error={!passwordValid(data.confirmPassword) || data.confirmPassword !== data.password}>
                    <input type="password" autoComplete="new-password" name="confirmPassword" value={data.confirmPassword} onChange={change} />
                </Label>
                <Instructions password={data.password} />
                <Buttons>
                    <button type="button" onClick={cancel}>
                        Abbrechen
                    </button>
                    <button type="button" onClick={save}>
                        Speichern
                    </button>
                </Buttons>
            </form>
        </Panel>
    );
};

export default ChangePassword;
