import { useEffect } from "react";
import styles from "./TimeoutBar.module.css";

const TimeoutBar = ({ seconds, label = "Verbleibende Zeit" }) => {
    useEffect(() => {
        const progressbar = document.getElementById("progressbar");
        progressbar.style.setProperty("transition-duration", seconds + "s");
        window.setTimeout(() => progressbar.classList.add(styles.zero), 100);
    }, [seconds]);

    return (
        <div className={styles.progressbar}>
            <div id="progressbar" className={styles.bar}>
                <span>{label}</span>
            </div>
        </div>
    );
};

export default TimeoutBar;
