import styles from "./Label.module.css";

const Label = ({ label, required = false, error = false, noLabelTag = false, children }) => {
    const classNames = [styles.label];
    required && classNames.push(styles.required);
    error && classNames.push(styles.error);
    if (noLabelTag) {
        return (
            <div>
                <span className={classNames.join(" ")}>{label}</span>
                {children}
            </div>
        );
    }
    return (
        <label>
            <span className={classNames.join(" ")}>{label}</span>
            {children}
        </label>
    );
};

export default Label;
