import { useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";

const useMobileApi = (callbackId, type) => {
    const { lastMessage } = useWebSocket(process.env.REACT_APP_MOBILE_WEBSOCKET_URL + "?callbackId=" + callbackId);
    const [result, setResult] = useState(null);

    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage.data);
            if (data.type && data.type === type && data.callbackId && data.callbackId === callbackId) {
                setResult(data);
            }
        }
    }, [lastMessage, callbackId, type]);

    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: "Connecting",
    //     [ReadyState.OPEN]: "Open",
    //     [ReadyState.CLOSING]: "Closing",
    //     [ReadyState.CLOSED]: "Closed",
    //     [ReadyState.UNINSTANTIATED]: "Uninstantiated"
    // }[readyState];

    // console.log("connectionStatus", connectionStatus);

    return result;
};

export default useMobileApi;
