import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { setTempSuccess } from "../message/messageSlice";

const namespace = "application";

const initialState = {
    user: null,
    appVersion: "1.2.2",
    backendVersion: null,
    webAppMenu: null,
    redirect: null
};

export const getVersion = createAsyncThunk(`${namespace}/getVersion`, async (_, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("version");
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const getApplicationData = createAsyncThunk(`${namespace}/getApplicationData`, async (_, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("application");
        dispatch(setTempSuccess(`Hallo ${data.user.firstname} 😊`));
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

const applicationSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        setRedirect: (state, { payload }) => {
            state.redirect = payload;
        },
        clearRedirect: (state, action) => {
            state.redirect = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVersion.fulfilled, (state, { payload }) => {
                state.backendVersion = payload.version;
            })
            .addCase(getApplicationData.fulfilled, (state, { payload }) => {
                state.user = payload.user;
                state.webAppMenu = payload.webAppMenu;
            })
            .addCase(logout.fulfilled, (state, { payload }) => {
                return initialState;
            });
    }
});

export const { setRedirect, clearRedirect } = applicationSlice.actions;

export default applicationSlice.reducer;
