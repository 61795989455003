import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import * as routes from "../../shared/routes";
import { setRedirect } from "../application/applicationSlice";

import { setError, clearAllMessages, setTempSuccess, setTempError } from "../message/messageSlice";

const isUnauthorized = (action) => {
    return action.type.endsWith("rejected") && action.payload === 401;
};

const initialState = {
    authenticated: false,
    autoSignupTried: false,
    forwardTo: null,
    requireNewPassword: false,
    validityCode: null,
    validityPeriod: null,
    message: null,
    callbackId: null
};

const namespace = "auth";

export const authenticated = (state) => state.auth.authenticated;

export const login = createAsyncThunk(`${namespace}/login`, async (authData, { dispatch, rejectWithValue }) => {
    dispatch(clearAllMessages());
    try {
        const { data } = await axios.post("login", authData);
        return data;
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(setError("Die E-Mail Adresse oder das Passwort ist falsch."));
        }
        return rejectWithValue(error.response.status);
    }
});

export const tryAutoSignUp = createAsyncThunk(`${namespace}/tryAutoSignUp`, async (forwardTo, { dispatch, rejectWithValue }) => {
    try {
        forwardTo && dispatch(setForwardTo(forwardTo));
        const { data } = await axios.get("autoSignup");
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const logout = createAsyncThunk(`${namespace}/logout`, async (_, { dispatch, rejectWithValue }) => {
    dispatch(clearAllMessages());
    try {
        const { data } = await axios.get("logout");
        dispatch(setTempSuccess("Du hast Dich erfolgreich abgemeldet"));
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const savePassword = createAsyncThunk(`${namespace}/savePassword`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.patch("password/" + payload.id, payload.data);
        dispatch(newPasswordSet());
        dispatch(setRedirect("/"));
        dispatch(setTempSuccess("Dein Passwort wurde erfolgreich gespeichert"));
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const passwordForgotten = createAsyncThunk(`${namespace}/passwordForgotten`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("passwordForgotten", payload);
        dispatch(setRedirect(routes.HOME));
        dispatch(setTempSuccess("Wir haben Dir eine E-Mail mit Instruktionen zur Rücksetzung Deines Passwortes gesendet"));
        return data;
    } catch (error) {
        switch (error.response.status) {
            case 406:
                dispatch(setRedirect(routes.HOME));
                dispatch(setTempError("Es wurde Dir bereits eine E-Mail mit Instruktionen zur Rücksetzung Deines Passwortes gesendet"));
                break;

            default:
        }

        return rejectWithValue(error.response.status);
    }
});

export const updatePassword = createAsyncThunk(`${namespace}/updatePassword`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.patch("password/change/" + payload.id, payload.data);
        dispatch(setRedirect(routes.HOME));
        dispatch(setTempSuccess("Dein Passwort wurde erfolgreich geändert"));
        return data;
    } catch (error) {
        switch (error.response.status) {
            case 406:
                dispatch(setTempError("Dein aktuelles Passwort ist nicht korrekt"));
                break;

            default:
        }

        return rejectWithValue(error.response.status);
    }
});

export const resetPassword = createAsyncThunk(`${namespace}/resetPassword`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("resetPassword", payload);
        dispatch(setRedirect(routes.HOME));
        dispatch(setTempSuccess("Dein neues Passwort wurde erfolgreich gespeichert"));
        return data;
    } catch (error) {
        switch (error.response.status) {
            case 406:
                dispatch(setRedirect(routes.HOME));
                dispatch(setTempError("Der Link zur Eingabe Deines neuen Passwortes ist nicht mehr gültig"));
                break;

            default:
                dispatch(setRedirect(routes.HOME));
                dispatch(setTempError("Es ist ein Fehler aufgetreten"));
        }

        return rejectWithValue(error.response.status);
    }
});

export const final2FaLogin = createAsyncThunk(`${namespace}/final2FaLogin`, async (callbackId, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("final2FaLogin/" + callbackId);
        return data;
    } catch (error) {
        if (error.response.status === 401) {
            dispatch(setError("Die Anmeldung ist fehlgeschlagen."));
        }
        return rejectWithValue(error.response.status);
    }
});

export const retry2FaLogin = createAsyncThunk(`${namespace}/retry2FaLogin`, async (callbackId, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("final2FaLogin/" + callbackId + "/retry");
        return data;
    } catch (error) {
        if (error.response.status === 404) {
            dispatch(setRedirect(routes.HOME));
            dispatch(setError("Die Anmeldung ist fehlgeschlagen."));
        }
        return rejectWithValue(error.response.status);
    }
});

export const delete2FaLogin = createAsyncThunk(`${namespace}/delete2FaLogin`, async (callbackId, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.delete("final2FaLogin/" + callbackId);
        dispatch(setRedirect(routes.HOME));
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const getLoginByEMailLink = createAsyncThunk(`${namespace}/getLoginByEMailLink`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("final2FaLogin/" + payload.callbackId + "/link", payload);
        dispatch(setRedirect(routes.HOME));
        dispatch(setTempSuccess("Es wurde Dir eine E-Mail gesendet, mit welcher Du Dich anmelden kannst."));
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const checkLoginByLink = createAsyncThunk(`${namespace}/checkLoginByLink`, async (callbackId, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("final2FaLogin/" + callbackId + "/check");
        return data;
    } catch (error) {
        dispatch(setRedirect(routes.HOME));
        dispatch(setTempError("Dieser Link ist nicht mehr gültig"));
        return rejectWithValue(error.response.status);
    }
});

export const loginByLink = createAsyncThunk(`${namespace}/loginByLink`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("final2FaLogin/" + payload.callbackId, payload);
        return data;
    } catch (error) {
        if (error.response.status === 400) {
            dispatch(setTempError("Das Password ist falsch."));
        } else if (error.response.status === 408) {
            dispatch(setTempError("Der Link ist nicht mehr gültig."));
        }
        return rejectWithValue(error.response.status);
    }
});

const authSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        setForwardTo: (state, action) => {
            state.forwardTo = action.payload;
        },
        forwardTo: (state, action) => {
            if (state.forwardTo) {
                window.location.href = state.forwardTo;
            }
        },
        newPasswordSet: (state, action) => {
            state.requireNewPassword = false;
        },
        cancel2FaLogin: (state, action) => {
            state.authenticated = false;
            state.validityCode = null;
            state.validityPeriod = null;
            state.message = null;
            state.callbackId = null;
        },
        clearValidityCode: (state, action) => {
            state.validityCode = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, { payload }) => {
                if (payload.validityCode) {
                    state.validityCode = payload.validityCode;
                    state.validityPeriod = payload.validityPeriod;
                    state.message = payload.message;
                    state.callbackId = payload.callbackId;
                } else {
                    state.authenticated = true;
                    state.requireNewPassword = payload.requireNewPassword;
                }
            })
            .addCase(retry2FaLogin.fulfilled, (state, { payload }) => {
                state.validityCode = payload.validityCode;
                state.validityPeriod = payload.validityPeriod;
                state.message = payload.message;
                state.callbackId = payload.callbackId;
            })
            .addCase(final2FaLogin.fulfilled, (state, { payload }) => {
                state.authenticated = true;
                state.requireNewPassword = payload.requireNewPassword;
                state.validityCode = null;
                state.validityPeriod = null;
                state.message = null;
                state.callbackId = null;
            })
            .addCase(delete2FaLogin.fulfilled, (state, { payload }) => {
                state.authenticated = false;
                state.validityCode = null;
                state.validityPeriod = null;
                state.message = null;
                state.callbackId = null;
            })
            .addCase(loginByLink.fulfilled, (state, { payload }) => {
                state.authenticated = true;
                state.requireNewPassword = payload.requireNewPassword;
                state.validityCode = null;
                state.validityPeriod = null;
                state.message = null;
                state.callbackId = null;
            })
            .addCase(checkLoginByLink.fulfilled, (state, { payload }) => {
                state.validityPeriod = payload.validityPeriod;
            })
            .addCase(logout.fulfilled, (state, { payload }) => {
                state.authenticated = false;
            })
            .addCase(tryAutoSignUp.fulfilled, (state, { payload }) => {
                state.authenticated = true;
                state.autoSignupTried = true;
            })
            .addCase(tryAutoSignUp.rejected, (state, { payload }) => {
                state.autoSignupTried = true;
            })
            .addMatcher(isUnauthorized, (state, { payload }) => {
                state.authenticated = false;
            });
    }
});

export const { setForwardTo, forwardTo, clearRefreshNote, newPasswordSet, cancel2FaLogin, clearValidityCode } = authSlice.actions;

export default authSlice.reducer;
