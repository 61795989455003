export const LOGIN = "/anmelden";
export const CONFIRM_2FA_LOGIN = "/anmeldung_bestaetigen";
export const TIMEOUT_2FA_LOGIN = "/anmeldung_abgelaufen";
export const LINK_LOGIN = "/linkanmeldung";
export const HOME = "/";
export const LOGOUT = "/abmelden";
export const NEW_PASSWORD = "/neues_passwort";
export const CHANGE_PASSWORD = "/passwort_aendern";
export const PASSWORD_FORGOTTEN = "/passwort_vergessen";
export const REFRESH_NOTE = "/achtung";
export const TWO_FACTOR_AUTHENTICATION = "/2fa";
