import styles from "./RegisterPhone.module.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import WaitForConfirmation from "./WaitForConfirmation/WaitForConfirmation";
import { registerPhone, stopRegistration } from "../../../features/twoFA/twoFaSlice";
import Iphone from "../../../assets/images/iPhoneScanQr.png";
import Buttons from "../../../ui/Buttons/Buttons";
import TimeoutBar from "../../../ui/TimeoutBar/TimeoutBar";

const RegisterPhone = ({ updateRegistration, cancel }) => {
    const [registerPhoneStep, setRegisterPhoneStep] = useState(1);
    const qrCode = useSelector((state) => state.twoFa.qrCode);
    const phoneId = useSelector((state) => state.twoFa.phoneId);
    const validityPeriod = useSelector((state) => state.twoFa.validityPeriod);
    const dispatch = useDispatch();

    useEffect(() => {
        if (registerPhoneStep === 3) {
            dispatch(registerPhone(phoneId));
        }
    }, [registerPhoneStep, dispatch, phoneId]);

    const clearRegistration = useCallback(() => {
        dispatch(stopRegistration());
    }, [dispatch]);

    useEffect(() => {
        return () => clearRegistration();
    }, [clearRegistration]);

    useEffect(() => {
        if (qrCode) {
            //console.log("Start Timer");
            window.setTimeout(() => cancel(), validityPeriod * 1000);
        }
    }, [qrCode, validityPeriod, cancel]);

    switch (registerPhoneStep) {
        case 1:
            return (
                <>
                    <div>
                        <div>
                            <span className={styles.large}>☞</span> Falls Du die App «AERZTEFON» noch nicht auf Deinem Mobiltelefon installiert hast, installiere diese:
                        </div>
                        <ul className={styles.list}>
                            <li>Besitzt Du ein iPhone, öffne den «App Store», suche nach «AERZTEFON» und installiere die App</li>
                            <li>Besitzt Du ein Android Gerät, öffne den «Play Store», suche nach «AERZTEFON» und installiere die App</li>
                        </ul>
                    </div>
                    <Buttons border>
                        <button type="button" onClick={cancel}>
                            Abbrechen
                        </button>
                        <button type="button" onClick={() => setRegisterPhoneStep(2)}>
                            Weiter
                        </button>
                    </Buttons>
                </>
            );

        case 2:
            return (
                <>
                    <div>
                        <span className={styles.large}>☞</span> Öffne die App «AERZTEFON» auf Deinem Mobiltelefon.
                    </div>
                    <Buttons border>
                        <button type="button" onClick={cancel}>
                            Abbrechen
                        </button>
                        <button type="button" onClick={() => setRegisterPhoneStep(3)}>
                            Weiter
                        </button>
                    </Buttons>
                </>
            );

        case 3:
            return (
                <>
                    <div>
                        <span className={styles.large}>☞</span> Scanne den folgenden QR Code mit der App «AERZTEFON»:
                    </div>
                    <div className={styles.images}>
                        <div>
                            <img src={Iphone} alt="iPhone" />
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: qrCode }} />
                    </div>
                    {validityPeriod && <TimeoutBar seconds={validityPeriod} />}
                    <Buttons border>
                        <button type="button" onClick={cancel}>
                            Abbrechen
                        </button>
                    </Buttons>
                    {phoneId && <WaitForConfirmation updateRegistration={updateRegistration} callbackId={phoneId} />}
                </>
            );

        default:
    }
};

export default RegisterPhone;
