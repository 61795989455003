import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../shared/axios";
import { logout } from "../auth/authSlice";
import { setTempSuccess } from "../message/messageSlice";

const namespace = "twoFa";

const initialState = {
    phoneId: null,
    platform: null,
    installationId: null,
    pushToken: null,
    qrCode: null,
    confirmState: null,
    validityPeriod: null
};

export const getTwoFaData = createAsyncThunk(`${namespace}/getTwoFaData`, async (_, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.get("twoFa");
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const registerPhone = createAsyncThunk(`${namespace}/registerPhone`, async (id, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.post("twoFa/" + id);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

export const updateTwoFa = createAsyncThunk(`${namespace}/updateTwoFa`, async (payload, { dispatch, rejectWithValue }) => {
    try {
        const { data } = await axios.patch("twoFa/" + payload.phoneId, payload);
        dispatch(setTempSuccess("Dein Mobiltelefon wurde erfolgreich registriert"));
        return data;
    } catch (error) {
        return rejectWithValue(error.response.status);
    }
});

const twoFaSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clear: (state, action) => {
            state.qrCode = null;
            state.validityPeriod = null;
            state.confirmState = null;
        },
        stopRegistration: (state, action) => {
            state.qrCode = null;
            state.validityPeriod = null;
            state.confirmState = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTwoFaData.fulfilled, (state, { payload }) => {
                state.phoneId = payload.phoneId;
                state.platform = payload.platform;
                state.installationId = payload.installationId;
            })
            .addCase(registerPhone.fulfilled, (state, { payload }) => {
                state.qrCode = payload.qrCode;
                state.validityPeriod = payload.validityPeriod;
            })
            .addCase(updateTwoFa.fulfilled, (state, { payload }) => {
                state.qrCode = null;
                state.confirmState = null;
                state.phoneId = payload.phoneId;
                state.platform = payload.platform;
                state.installationId = payload.installationId;
            })
            .addCase(logout.fulfilled, (state, { payload }) => {
                return initialState;
            });
    }
});

export const { clear, stopRegistration } = twoFaSlice.actions;

export default twoFaSlice.reducer;
