export const capsCount = (value) => {
    return (value.match(/[A-Z]/g) || []).length > 0;
};
export const smallCount = (value) => {
    return (value.match(/[a-z]/g) || []).length > 0;
};
export const numberCount = (value) => {
    return (value.match(/[0-9]/g) || []).length > 0;
};
export const symbolCount = (value) => {
    return (value.match(/[^\wäöüÄÖÜ ]/g) || []).length > 0;
};

export const passwordValid = (password) => {
    return password.length >= process.env.REACT_APP_MIN_PASSWORD_LENGTH && capsCount(password) > 0 && smallCount(password) > 0 && numberCount(password) > 0 && symbolCount(password) > 0;
};

export const getPasswordError = (password, confirmPassword) => {
    if (password.length < process.env.REACT_APP_MIN_PASSWORD_LENGTH) {
        return `Das Passwort muss mindestens ${process.env.REACT_APP_MIN_PASSWORD_LENGTH} Zeichen haben`;
    } else if (!capsCount(password)) {
        return "Das Passwort muss mindestens einen Grossbuchstaben enthalten";
    } else if (!smallCount(password)) {
        return "Das Passwort muss mindestens einen Kleinbuchstaben enthalten";
    } else if (!numberCount(password)) {
        return "Das Passwort muss mindestens einen Zahl enthalten";
    } else if (!symbolCount(password)) {
        return "Das Passwort muss mindestens ein Sonderzeichen (@$!%*?&...) enthalten";
    } else if (password !== confirmPassword) {
        return "Das Passwort und dessen Bestätigung müssen identisch sein";
    }
};
