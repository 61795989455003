import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authenticated } from "../../features/auth/authSlice";

import * as routes from "../../shared/routes";
import { useEffect } from "react";
import { getApplicationData } from "../../features/application/applicationSlice";

const RequireAuth = () => {
	const isAuthenticated = useSelector(authenticated);
	const application = useSelector((state) => state.application);
	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (isAuthenticated && !application.user) {
			dispatch(getApplicationData());
		}
	}, [dispatch, isAuthenticated, application]);

	return isAuthenticated ? <Outlet /> : <Navigate to={routes.LOGIN} state={{ from: location }} replace />;
};

export default RequireAuth;
