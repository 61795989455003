import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate, useSearchParams } from "react-router-dom";

import { authenticated, forwardTo, tryAutoSignUp } from "./features/auth/authSlice";
import { clearRedirect, getVersion } from "./features/application/applicationSlice";
import * as routes from "./shared/routes";

import Login from "./containers/Login/Login";
import WaitForMobileConfirmation from "./containers/Login/WaitForMobileConfirmation/WaitForMobileConfirmation";
import Home from "./containers/Home/Home";
import NewPassword from "./containers/NewPassword/NewPassword";
import Layout from "./hoc/Layout/Layout";
import RequireAuth from "./components/RequireAuth/RequireAuth";
import RequireNoAuth from "./components/RequireNoAuth/RequireNoAuth";
import Logout from "./containers/Logout/Logout";
import ChangePassword from "./containers/ChangePassword/ChangePassword";
import PasswordForgotten from "./containers/PasswordForgotten/PasswordForgotten";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import TwoFA from "./containers/TwoFA/TwoFA";
import MobileConfirmationTimeout from "./containers/Login/MobileConfirmationTimeout/MobileConfirmationTimeout";
import LinkLogin from "./containers/Login/LinkLogin/LinkLogin";

const App = () => {
    const isAuthenticated = useSelector(authenticated);
    const autoSignupTried = useSelector((state) => state.auth.autoSignupTried);
    const requireNewPassword = useSelector((state) => state.auth.requireNewPassword);
    const forward = useSelector((state) => state.auth.forwardTo);
    const redirect = useSelector((state) => state.application.redirect);
    const appVersion = useSelector((state) => state.application.appVersion);
    const backendVersion = useSelector((state) => state.application.backendVersion);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const calledURL = searchParams.get("forwardTo");

    useEffect(() => {
        if (!backendVersion) {
            dispatch(getVersion());
        } else if (backendVersion !== appVersion) {
            window.location.reload();
        }
    }, [dispatch, backendVersion, appVersion]);

    useEffect(() => {
        if (!autoSignupTried) {
            dispatch(tryAutoSignUp(calledURL));
        }
    }, [autoSignupTried, calledURL, dispatch]);

    useEffect(() => {
        if (isAuthenticated && !requireNewPassword && forward) {
            dispatch(forwardTo());
        }
    }, [isAuthenticated, requireNewPassword, forward, dispatch, navigate]);

    useEffect(() => {
        if (isAuthenticated && requireNewPassword) {
            navigate(routes.NEW_PASSWORD);
        }
    }, [isAuthenticated, requireNewPassword, navigate]);

    useEffect(() => {
        if (redirect) {
            navigate(redirect);
            dispatch(clearRedirect());
        }
    }, [redirect, dispatch, navigate]);

    if (!autoSignupTried) {
        return null;
    }

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route element={<RequireAuth />}>
                    <Route path={routes.LOGOUT} element={<Logout />} />
                    <Route path={routes.CHANGE_PASSWORD} element={<ChangePassword />} />
                    {requireNewPassword ? <Route path={routes.NEW_PASSWORD} element={<NewPassword />} /> : <Route path="/" element={<Home />} />}
                    <Route path={routes.TWO_FACTOR_AUTHENTICATION} element={<TwoFA />} />
                    <Route path="*" element={<Navigate to={requireNewPassword ? routes.NEW_PASSWORD : "/"} replace />} />
                </Route>
                <Route element={<RequireNoAuth />}>
                    <Route path={routes.PASSWORD_FORGOTTEN + "/:resetPasswordKey"} element={<ResetPassword />} />
                    <Route path={routes.PASSWORD_FORGOTTEN} element={<PasswordForgotten />} />
                    <Route path={routes.LOGIN} element={<Login />} />
                    <Route path={routes.CONFIRM_2FA_LOGIN} element={<WaitForMobileConfirmation />} />
                    <Route path={routes.TIMEOUT_2FA_LOGIN} element={<MobileConfirmationTimeout />} />
                    <Route path={routes.LINK_LOGIN + "/:callbackId?"} element={<LinkLogin />} />
                    <Route path="*" element={<Navigate to={routes.LOGIN} replace />} />
                </Route>
            </Route>
        </Routes>
    );
};

export default App;
