import { ReactComponent as ArrowDown } from "../../../assets/images/arrowDown.svg";

const WebAppMenu = ({ menuItems, hideMenu = false }) => {
	if (!menuItems) {
		return null;
	}

	const menu = menuItems.map((menu, index) => {
		if (menu.active) {
			return (
				<li key={index}>
					<span className="active">{menu.name}</span>
				</li>
			);
		} else {
			return (
				<li key={index}>
					<a href={menu.url}>{menu.name}</a>
				</li>
			);
		}
	});

	return (
		<li className={"parent" + (hideMenu ? " disabled" : "")}>
			<span>
				<ArrowDown className="menuArrow" />
				Applikationen
			</span>
			<ul className="appMenu">{menu}</ul>
		</li>
	);
};

export default WebAppMenu;
