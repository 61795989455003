const Panel = ({ title, size = "", children }) => {
	return (
		<div className={"panel" + (size ? " " + size : "")}>
			<div className="panelHead">{title}</div>
			{children}
		</div>
	);
};

export default Panel;
