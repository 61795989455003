import { useEffect, useState } from "react";
import styles from "./MobileConfirmationTimeout.module.css";
import { useDispatch, useSelector } from "react-redux";
import Panel from "../../../hoc/Panel/Panel";
import { delete2FaLogin, getLoginByEMailLink, retry2FaLogin } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import * as routes from "../../../shared/routes";
import ReactTextareaAutosize from "react-textarea-autosize";
import Label from "../../../ui/Label/Label";
import Buttons from "../../../ui/Buttons/Buttons";

const MobileConfirmationTimeout = () => {
    const dispatch = useDispatch();
    const callbackId = useSelector((state) => state.auth.callbackId);
    const validityCode = useSelector((state) => state.auth.validityCode);
    const navigate = useNavigate();
    const [canNotConform, setCanNotConform] = useState(false);
    const [reason, setReason] = useState("");

    useEffect(() => {
        if (validityCode) {
            navigate(routes.CONFIRM_2FA_LOGIN);
        }
    }, [validityCode, navigate]);

    useEffect(() => {
        if (!callbackId) {
            navigate(routes.HOME);
        }
    }, [callbackId, navigate]);

    const retry = () => {
        dispatch(retry2FaLogin(callbackId));
    };

    const eMailLogin = () => {
        dispatch(getLoginByEMailLink({ callbackId, reason }));
    };

    const reasenNotOk = reason.trim().length < 10;

    return (
        <Panel title="Zeitüberschreitung" size="small">
            <div className={styles.content}>
                Du hast Deine Anmeldung nicht innerhalb der vorgegebenen Zeit bestätigt.
                <div className={styles.checkboxes}>
                    <label className={styles.checkbox}>
                        <input type="checkbox" checked={canNotConform} onChange={(e) => setCanNotConform(e.target.checked)} />
                        <span>Ich kann meine Anmeldung nicht bestätigen mit meinem Mobiltelefon</span>
                    </label>
                    {canNotConform && (
                        <div className={styles.indent}>
                            <Label label="Begründung" required error={reasenNotOk}>
                                <ReactTextareaAutosize value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Mindestens 10 Zeichen..." />
                            </Label>
                            <div className={styles.note}>Du wirst im Anschluss eine E-Mail erhalten mit welcher Du Dich anmelden kannst.</div>
                        </div>
                    )}
                </div>
                <Buttons>
                    <button type="button" onClick={() => dispatch(delete2FaLogin(callbackId))}>
                        Abbrechen
                    </button>
                    {canNotConform ? (
                        <button type="button" onClick={eMailLogin} disabled={reasenNotOk}>
                            OK
                        </button>
                    ) : (
                        <button type="button" onClick={retry}>
                            Erneut versuchen
                        </button>
                    )}
                </Buttons>
            </div>
        </Panel>
    );
};

export default MobileConfirmationTimeout;
